export const api = window._env_.API_URL;
// export const api = "https://api.voyr.me";
// export const loginApi = "https://api.voyr.me/v1/manager/logIn";
// export const regionApi = "https://api.voyr.me/v1/ipLocation";
// export const resetPasswordApi =  "https://api.voyr.me/v1/manager/forgotPassword";



// export const api = "https://apiv1.fanzly.app";
// export const loginApi = "https://apiv1.fanzly.app/v1/manager/logIn";
// export const regionApi = "https://apiv1.fanzly.app/v1/ipLocation";
// export const resetPasswordApi = "https://apiv1.fanzly.app/v1/manager/forgotPassword";