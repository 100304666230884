/* eslint-disable semi */
// Dependency Imports
// eslint-disable-next-line no-unused-vars
import React from "react";

// Custom Imports
import "../../static/scss/App.scss";
import "../../static/scss/search/search.scss";

// eslint-disable-next-line no-unused-vars
import { APP_NAME } from "../../lib/config";
// eslint-disable-next-line no-unused-vars
import CustomInput from "../../components/InputBox/Input.jsx";
import MainHeader from "../../containers/headers/mainHeader.jsx";
import Wrapper from "../../hoc/Wrapper";

class SearchPage extends React.Component {
  handleInputChange = (event) => {};
  render() {
    return (
      <Wrapper>
        {/* Header Section */}
        <div className="App">
          <MainHeader>
            <h3 className="headerMainTitle">
              Welcome to Search Portal, by {APP_NAME}.
            </h3>

            <p>
              <CustomInput
                id="searchBoxSPage"
                // id={234}
                className="searchBox"
                onChange={this.handleInputChange}
                placeholder="Type Here.."
              />
            </p>
          </MainHeader>
        </div>

        {/* Body Section */}
        <div className="mainBodyPart"></div>
      </Wrapper>
    );
  }
}

export default SearchPage;
