import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RevLoader from "../loader/RevLoader"
import './styles.css'
import { IMAGE_URL } from "../../lib/config";
import moment from "moment";


/**
 * @Author Jai
 * @Date 20 August, 2021
 * @Description Created a User profile dialog for gettin the creator details.
 */


class UserProfileModel extends Component {
    state ={ 
        data : [],
    }

    uppercase = word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
    };
    

render() {
    const {creatorRes, message } = this.props
    return (
        <div>
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
                scroll={this.props.scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
            <DialogTitle className="align-center" id="scroll-dialog-title">User Data</DialogTitle>
            <DialogContent className="d-flex align-items-center justify-content-center"  dividers={this.props.scroll === 'paper'}>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={this.props.descriptionElementRef}
                    tabIndex={-1}
                >

                {this.props.loader ? 
                    <div>
                        <span className="numericValue mr-auto ml-3">
                            {this.props.loader ? <RevLoader /> : <></>}
                        </span>
                    </div> :

                    <div className="clearfix">
                        <div className="">  
                        {creatorRes.map((data,index) => (
                            <div className="animated fadeIn" 
                            key={data.id}
                            >
                            <div className="card">
                                <div className="card-body">
                                <div className="avatar">
                                    <img
                                    src={`${IMAGE_URL}${data.profilePic}`}
                                    className="card-img-top"
                                    alt=""
                                    />
                                </div>
                                <h5 className="card-title">
                                    {this.uppercase(data.firstName) +
                                    " " +
                                    this.uppercase(data.lastName)}
                                </h5>
                               <div className="row">
                                   <div className="col-md-5"><span>Email</span></div>
                                   <div className="col-md-7"><span>{data.email}</span></div>

                                   <div className="col-md-5"><span>Phone</span></div>
                                   <div className="col-md-7"><span>{`+91 ${data.phoneNumber}`}</span></div>

                                   <div className="col-md-5"><span>User Type</span></div>
                                   <div className="col-md-7"><span>{data.userTypeText}</span></div>

                                   <div className="col-md-5"><span>Registration Date</span></div>
                                   <div className="col-md-7"><span>{moment.unix(data.creationDate/1000).format("DD-MMM-YYYY")}</span></div> 

                                   <div className="col-md-5"><span>Date of Birth</span></div>
                                   <div className="col-md-7"><span>{moment(data.dateOfBirth).format("DD-MMM-YYYY")}</span></div>                                                                                                                                            
                               </div>
                                
                                </div>
                            </div>
                            </div>
                        ))}
                        </div>
                    </div>
    
                    }

             


                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
            Close
            </Button>
            </DialogActions>
            </Dialog>
        </div>
    )
}
}

export default UserProfileModel