import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { TableContainer } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import getSymbolFromCurrency from 'currency-symbol-map';
import './index.css'
import { userProfile } from '../../../../services/chartDetailsApi';
import UserProfileModel from '../../../../components/modal/userProfile';

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
    width: "100%",
    height: "100%"
  },
});

export default function SimpleTable(props) {

  //! state define for user dialog box
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [userResponse, setUserResponse] = React.useState([]);
  const [message, setMessage] = React.useState([]);
  const descriptionElementRef = React.useRef(null);
  const [loader, setLoader] = React.useState(true);


  //! Clicking on the Creator user name dialog box will be open
  const handleClickOpen = (scrollType, creatorId) => {
    getData(creatorId)
    setOpen(true);
    setLoader(true)
    setScroll(scrollType);
  };

  //! Dialog box will close
  const handleClose = () => {
    setOpen(false);
    // setLoader(false)
  };


   //! Calling the API for getting the user profile data
  const getData = (creatorId
    ) => {
      userProfile(creatorId
      )
        .then((response) => {
          setUserResponse(response.data.result)
          setLoader(false)
          
        })
        .catch((error) => {
          console.log("Api error", error && error.response &&  error.response.data && error.response.data.message);
          let message =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : "Data Not Found For the Given Date Range!";
          setMessage(message)
          setLoader(false)

        });
    }; 

    
    React.useEffect(() => {
      if (open) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
    }, [open]);

  const classes = useStyles();
  let columns;

  let valueFormatter = (value, index) => {
    if (value >= 10000 && value < 1000000) {
      let kValue = value / 1000;
      return (kValue.toFixed(2)) + " K";
    }
    if (value >= 100000) {
      let kValue = value / 1000000;
      return (kValue.toFixed(2)) + " M";
    }
    return Number(value.toFixed(2));
  }

  if (!props.table.length) {
    return <div className="text-center no-data" style={{ fontSize: "12px", fontWeight: 400 }}>No data found between selected date range.</div>
  }
  if (props.table.length) {
    columns = Object.keys(props.table[0]);
  }


  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#2A3F54",
      color: 'white',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  return (
    <>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((columnName, index) => {
              if (columnName === "user_id" || columnName === "creatorId") return ;
              return (
                <TableCell key={index} style={{ fontSize: "12px", fontWeight: 500 }} align={index == 0 ||  columnName == "Title" ||  columnName == "Creator Username" ? "left" : "right"}>{columnName.charAt(0).toUpperCase() + columnName.slice(1, columnName.length)}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.table.map((dict, index) => (
            <TableRow key={index}>
              {columns.map((keys, index) => {
                if (keys === "user_id" || keys === "creatorId") return ;
                if (keys.startsWith("%")) {
                  return (
                    <TableCell component="left">
                      <div className="progress text-center" data-toggle="tooltip" title={dict[keys] + "%"}>
                        <div className="progress-bar" role="progressbar" style={{ width: dict[keys] + "%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </TableCell>
                  )
                }

                else if ( keys === 'No. of Posts' &&  index === 1) {
                  return (
                    <TableCell component="right" align="right" style={{ fontSize: "12px" }}>
                      {(dict[keys])}
                    </TableCell>
                  )
                }

                else if (index === 0  && keys === 'Title'){
                  return (
                    <TableCell component="left" align="left" style={{ fontSize: "12px"}} >
                     {(dict[keys] === '' ? 'N/A' : dict[keys])} 
                    </TableCell>
                  )
                }

                else if (keys === 'Username' || keys === 'Creator Username' ){
                  return (
                    <TableCell
                    className={`${dict['Username'] !== '' || dict['Creator Username'] !== ''  ? 'show__clickable' : ''}`}
                    component="left" align="left" style={{fontSize: "12px", color:"blue", cursor:"pointer"}} 
                    
                    onClick={ () =>  handleClickOpen('paper', dict['user_id'] || dict['creatorId']) }
                    >
                      {/* {console.log('keys', keys, 'dict', dict, 'user id ',dict['user_id'], 'creator id', dict['creatorId']) } */}
                     {(dict[keys] === '' ? 'N/A' : dict[keys])} 
                    </TableCell>
                  )
                }


                else if (keys === "Revenue"){
                  return (
                    <TableCell component="right" align="right" style={{ fontSize: "12px" }}>
                      {`${getSymbolFromCurrency(props.currency)} `}{valueFormatter(dict[keys] || 0 )}
                    </TableCell>
                  )
                }

                return (
                  <TableCell component="left" style={{ fontSize: "12px" }}>
                    <LightTooltip title={dict[keys]} placement="bottom-start" arrow><span>{dict && dict[keys] && dict[keys].length > 25 ? dict[keys].slice(0, 25) + "..." : dict[keys]}</span></LightTooltip>
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>


      {/* //! if will click on the creator user name then one dialog box will open */}
      <UserProfileModel 
          message = {message}
          open = {open}
          handleClose = {handleClose}
          descriptionElementRef= {descriptionElementRef}
          scroll = {scroll}
          creatorRes={userResponse}
          loader= {loader}
          />
      </>
  );
}