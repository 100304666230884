export const statusFilter = [
  { id: 0, name: "All" },
  { id: 3, name: "Request Cancelled" },
  { id: 4, name: "Customer Cancelled" },
  { id: 6, name: "Driver Is On The Way" },
  { id: 7, name: "Driver Has Arrived" },
  { id: 9, name: "Trip Started" },
  { id: 12, name: "Completed" },
  { id: 13, name: "Booking Expired" },
  // { id: 1, name: "New" },
  // { id: 2, name: "Accepted" },
  // { id: 5, name: "Driver Cancelled" },
  // { id: 8, name: "Loading Started" },
  // { id: 10, name: "Reached To Drop Location" },
  // { id: 11, name: "Unloaded" },
];
