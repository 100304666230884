import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

function getModalStyle(props) {
  const top = 50;
  const left = 50;
  return {
    width: props,
    top: `${top}%`,
    left: `${left}%`,
    borderRadius: "8px",
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
  },
});

class MAT_UI_Modal extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={this.props.isOpen} onClose={this.props.toggle}>
          <div style={getModalStyle(this.props.width)} className={classes.paper}>
            {this.props.children}
          </div>
        </Modal>
      </div>
    );
  }
}

MAT_UI_Modal.propTypes = {
  classes: PropTypes.object.isRequired,
};

// We need an intermediary variable for handling the recursive nesting.
const _MAT_UI_Modal = withStyles(styles)(MAT_UI_Modal);

export default _MAT_UI_Modal;
