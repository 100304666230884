import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import SubGraph from './SubGraph/index'

class userLineGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className="overviewBody" style={{ padding: "0px 20px 0px 5px" }}>
        <SubGraph
          userType = {this.props.userType}
          userStatus= {this.props.userStatus}
          totalUser = {this.props.summary['Total users']}
          totalActiveUser = {this.props.summary['Total Action Users']}
        />

        <ReactApexChart
          options={this.props.config.options}
          series={this.props.config.series}
          type="line"
          height={380}
        />
      </div>
    );
  }
}

export default userLineGraph;
