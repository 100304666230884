import React, { Component } from "react";
import { dateFilter } from "../../../../fixtures/dateFilter/dateFIlter";
import DateRangePicker from "../../../../components/dateRangePicker/dateRangePicker";
import moment from "moment-timezone";
import { withStyles } from "@material-ui/core/";
// import Select from "../../../../components/AntSelect";
import { countries } from "../../../../fixtures/dummyApi/countries";
// import ReactSelect from "../../../../components/reactSelect/reactSelect";
import "antd/dist/antd.css";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Input from "../../../../components/InputBox/Input";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  menuItem: {
    width: "300px",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontSize: 12.8,
  },
  selectCountryButton: {
    width: "59px",
    background: "#FFF",
    border: "1px solid #bbb",
    borderRadius: "4px",
    padding: "7px 12px",
    marginLeft: "10px",
  },
});

class index extends Component {
  constructor(props) {
    super();
    this.state = {
      date: [],
      selectedGroupByPeriod: { value: "0", label: "Hour" },
      selectGroupByPeriodList: [
        { value: 0, label: "Hour" },
        { value: 1, label: "Day" },
        { value: 2, label: "Week" },
        { value: 3, label: "Month" },
        { value: 4, label: "Quarter" },
        { value: 5, label: "Year" },
        { value: 6, label: "Hour Of Day" },
        { value: 7, label: "Day Of Week" },
      ],

      numberOfDays: 0,

      //currency
      anchorEl: null,
      selectedCurrency: props.currency,
      groupBy: props.groupBy,
      groupByName: props.groupByName,
      filterData: [...countries],
      currencyFilter: {
        label: "Currency filter",
        input: {
          name: "currencyFilter",
          type: "text",
          value: "",
        },
      },
    };
  }

  setGroupBy = (numberOfDays) => {
    if (numberOfDays === 0) {
      localStorage.setItem(
        "selectedGroupBy",
        JSON.stringify(this.state.selectGroupByPeriodList[0])
      );
      this.setState({ groupBy: 0 }, () =>
        this.props.headerStateHandler("groupBy", this.state.groupBy)
      );
      this.setState(
        { groupByName: this.state.selectGroupByPeriodList[0] },
        () =>
          this.props.headerStateHandler("groupByName", this.state.groupByName)
      );
    } else if (numberOfDays > 0 && numberOfDays <= 7) {
      localStorage.setItem(
        "selectedGroupBy",
        JSON.stringify(this.state.selectGroupByPeriodList[1])
      );
      this.setState({ groupBy: 1 }, () =>
        this.props.headerStateHandler("groupBy", this.state.groupBy)
      );
      this.setState(
        { groupByName: this.state.selectGroupByPeriodList[1] },
        () =>
          this.props.headerStateHandler("groupByName", this.state.groupByName)
      );
    } else if (numberOfDays > 7 && numberOfDays <= 90) {
      if (numberOfDays > 30 && numberOfDays <= 90) {
        localStorage.setItem(
          "selectedGroupBy",
          JSON.stringify(this.state.selectGroupByPeriodList[2])
        );
      }
      if (numberOfDays > 7 && numberOfDays <= 30) {
        localStorage.setItem(
          "selectedGroupBy",
          JSON.stringify(this.state.selectGroupByPeriodList[1])
        );
      }
      this.setState({ groupBy: 1 }, () =>
        this.props.headerStateHandler("groupBy", this.state.groupBy)
      );
      this.setState(
        { groupByName: this.state.selectGroupByPeriodList[1] },
        () =>
          this.props.headerStateHandler("groupByName", this.state.groupByName)
      );
    } else {
      localStorage.setItem(
        "selectedGroupBy",
        JSON.stringify(this.state.selectGroupByPeriodList[3])
      );
      this.setState({ groupBy: 3 }, () =>
        this.props.headerStateHandler("groupBy", this.state.groupBy)
      );
      this.setState(
        { groupByName: this.state.selectGroupByPeriodList[3] },
        () =>
          this.props.headerStateHandler("groupByName", this.state.groupByName)
      );
    }
  };

  selectGroupByPeriodHandler = (...arges) => {
    if (arges[0]) {
      let startDate =
        arges[0].length !== 0
          ? arges[0][0].format("YYYY-MM-DD HH:mm:ss")
          : null;
      let endDate =
        arges[0].length !== 0
          ? arges[0][1].format("YYYY-MM-DD HH:mm:ss")
          : null;
      let startDatefordiff = moment(startDate).startOf("day");
      let endDatefordiff =
        moment().diff(endDate, "h") === 0 || moment().diff(endDate, "h") === "0"
          ? moment()
          : moment(endDate).endOf("day");
      let numberOfDays = endDatefordiff.diff(startDatefordiff, "days");
      endDatefordiff = moment(endDatefordiff).format("YYYY-MM-DD HH:mm:ss");
      const { selectedGroupByPeriod } = this.state;
      localStorage.setItem("startDate", String(startDate));
      localStorage.setItem("endDate", String(endDatefordiff));
      localStorage.setItem(
        "selectedGroupBy",
        JSON.stringify(selectedGroupByPeriod)
      );
      this.setState(
        {
          startDate: startDate,
          endDate: endDatefordiff,
        },
        () =>
          this.props.headerStateHandler("date", {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          })
      );
      if (numberOfDays !== this.state.numberOfDays) {
        this.setState({ numberOfDays: numberOfDays });
        return this.setGroupBy(numberOfDays);
      }
    }
  };

  getCurrencyData = (obj) => {
    this.setState(
      {
        selectedCurrency: obj.currencies[0].code,
      },
      () =>
        this.props.headerStateHandler(
          "selectedCurrency",
          this.state.selectedCurrency
        )
    );
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  filterCurrency = (e) => {
    this.setState({
      currencyFilter: {
        ...this.state.currencyFilter,
        input: {
          ...this.state.currencyFilter.input,
          value: e.target.value,
        },
      },
    });

    let filterData = countries.filter((item, index) => {
      return (
        item.currencies.findIndex(item => item.code && item.code.toLowerCase().includes(e.target.value.toLowerCase()) )  
       !==
          -1 ||
        item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      );
    });
    this.setState({
      filterData,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="d-flex align-items-center">
        {/* <span className="mr-2 globalFontSize">Country: </span>
        <div className="mt-2 mb-2" style={{ width: "8rem" }}>
          <Select
            handleSelectChange={(id, key) =>
              this.props.selectHandle(id, "countries")
            }
            data={this.props.countries}
            type="countries"
            selected={this.props.country}
            placeholder="select country"
          ></Select>
        </div> */}
        {/* <span className="mx-2 globalFontSize">City: </span>
        <div className="mt-2 mb-2" style={{ width: "8rem" }}>
          <Select
            handleSelectChange={(id, key) =>
              this.props.selectHandle(id, "cities")
            }
            data={this.props.cities}
            type="gread"
            placeholder="select city"
            selected={this.props.city}
          ></Select>
        </div> */}

        {/* <span className="mx-2 globalFontSize">Zone: </span>
        <div className="mt-2 mb-2" style={{ width: "8rem" }}>
          <Select
            handleSelectChange={(id, key) =>
              this.props.selectHandle(id, "zones")
            }
            data={this.props.zones}
            type="section"
            placeholder="select zone"
            selected={this.props.zone}
          ></Select>
        </div> */}

        <span className="mx-2 globalFontSize">Date Range: </span>
        <div className="mt-2 mb-2">
          <DateRangePicker
            ranges={{ ...dateFilter }}
            value={[this.props.startDate, this.props.endDate]}
            onChange={this.selectGroupByPeriodHandler}
          />
        </div>
        <div className="globalFontSize ml-2">
          Currency:
          <Button
            className={classes.selectCountryButton}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(event) =>
              this.setState({ anchorEl: event.currentTarget })
            }
          >
            {this.props.currency}
          </Button>
          <Menu
            style={{ minHeight: "300px", maxHeight: "300px" }}
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
          >
            <div className="currency-hover-class">
              <MenuItem>
                Currency:
                <Input
                  id=""
                  className="filterCurrency"
                  {...this.state.currencyFilter.input}
                  onChange={(e) => this.filterCurrency(e)}
                ></Input>
              </MenuItem>
              {this.state.filterData.map((k, index) => (
                <MenuItem
                  key={index}
                  className={classes.menuItem}
                  onClick={() => this.getCurrencyData(k)}
                >
                  <span>
                    <img src={k.flag} height={25} width={25} alt="currency" />
                  </span>
                  <span>^</span>
                  <span className="pl-3 currencySymbol">
                    {k.currencies[0].symbol}
                  </span>
                  <span className="pl-3">{k.name}</span>
                </MenuItem>
              ))}
            </div>
          </Menu>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(index);
