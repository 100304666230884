import { getForReport } from "../lib/request";

export const totalEarning = (
  start_time,
  end_time,
  group_by,
  source,
  currency,
  symbol,
  colName,
  sortType,
  skip,
  limit
) => {
  console.log("colName", colName,
    "sortType",sortType)
  let URL =  `/analytics/detailed_charts/total-earning?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&currency=${currency}&source=${source}&currency_symbol=${symbol}&sort_clm=${colName}&sort_type=${sortType}&skip=${skip || 0}&limit=${limit || 10}`
  return getForReport(URL);
}

export const topNSubscription = (
  start_time,
  end_time,
  group_by,
  source,
  currency,
  symbol,
  colName,
  sortType,
  skip,
  limit,

) => {
  let URL = `/analytics/detailed_charts/top-n-subscriptions?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&currency=${currency}&source=${source}&currency_symbol=${symbol}&sort_clm=${encodeURI(colName)}&sort_type=${sortType}&skip=${skip || 0}&limit=${limit || 10}`
  return getForReport(URL);
}


export const topNpost = (
  start_time,
  end_time,
  group_by,
  currency,
  symbol,
  colName,
  sortType,
  skip,
  limit,
) => {
  let URL = `/analytics/detailed_charts/top-n-posts?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&currency=${currency}&source=ALL&currency_symbol=${symbol}&sort_clm=${encodeURI(colName)}&sort_type=${sortType}&skip=${skip || 0}&limit=${limit || 10}`
  return getForReport(URL);
}


export const topCreators = (
  start_time,
  end_time,
  group_by,
  source,
  currency,
  symbol,
  skip,
  limit,
) => {
  let URL = `/analytics/detailed_charts/no-of-creators?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&currency=${currency}&source=${source}&currency_symbol=${symbol}&skip=${skip || 0}&limit=${limit || 10}`
  return getForReport(URL);
}


export const userSignup = (
  start_time,
  end_time,
  group_by,
  userType,
  country,
  colName,
  sortType,
  skip,
  limit,
) => {
  let URL = `/analytics/detailed_charts/user-signups?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&source=All&user_type=${userType}&country=IN&sort_clm=${encodeURI(colName)}&sort_type=${sortType}&skip=${skip || 0}&limit=${limit || 10}`
  return getForReport(URL);
}


export const totalUsers = (
  start_time,
  end_time,
  group_by,
  userType,
  userStatus,
  country,
  colName,
  sortType,
  skip,
  limit,
) => {
  console.log()
  let URL = userStatus ? `/analytics/detailed_charts/total-users?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&user_type=${userType}&status_code=${userStatus}&country=IN&sort_clm=${encodeURI(colName)}&sort_type=${sortType}&skip=${skip || 0}&limit=${limit || 10}`
  : `/analytics/detailed_charts/total-users?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&user_type=${userType}&country=${country || 'IN'}&sort_clm=${encodeURI(colName)}&sort_type=${sortType}&skip=${skip || 0}&limit=${limit || 10}`
  return getForReport(URL);
}

export const getCountries = () => {
  let URL = "/v1/wallet/country?fetchSize=1000";
  return getForReport(URL);
};


export const userSession = (
  start_time,
  end_time,
  group_by,
  colName,
  sortType,
  skip,
  limit,
) => {
  let URL = `/analytics/detailed_charts/user-session-logs?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&device=0&sort_clm=${encodeURI(colName)}&sort_type=${sortType}&skip=${skip || 0}&limit=${limit || 10}`
  return getForReport(URL);
}

export const TopNChats = (
  start_time,
  end_time,
  group_by,
  currency,
  symbol,
  colName,
  sortType,
  skip,
  limit,
) => {
  let URL =  `/analytics/detailed_charts/top-n-chats?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&currency=${currency}&currency_symbol=${symbol}&sort_clm=${encodeURI(colName)}&sort_type=${sortType}&skip=${skip || 0}&limit=${limit || 10}`

  return getForReport(URL);
}


export const userProfile = (
  creatorId
) => {
  let URL =  `/python/users/?userId=${creatorId}&contactMasked=0`
  return getForReport(URL)
}


