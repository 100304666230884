import React, { Component } from "react";
import Link from "../../../../components/Link/Link";
import TopNTable from "./topNTable";
import RevLoader from "../../../../components/loader/RevLoader";
import ReactSelect from '../../../../components/Select';

class topNPosts extends Component {
  state = {
    source: [
      {label: "Revenue", value: "REVENUE"},
      {label: "No of Post", value: "NO_OF_POST"},
    ],
    selectedsource: {label: "Revenue", value: "REVENUE"}
  }

  selectHandler = (option) => {
    this.setState({
        selectedsource: option,
    }, () => this.props.sourceChangeHandler(option.value));
  }

  render() {
    return (
      <div className="card">
        <div className="spaceBetween mt-3 ml-3 mr-3">
          <span className="label globalFontSize fontweightone">
            {this.props.title}
          </span>
          { this.props.viewActivity ?
          <span className="link globalFontSize fontweightone">
            <Link url={`${this.props.viewActivity}`}>View Activity</Link>
          </span> : ''
          }
        </div>
        
        <div className="d-flex align-items-center justify-content-end">
          <span className="numericValue mr-auto ml-3">
             {this.props.loader ? <RevLoader /> : <></>}
          </span>
          {this.props.title === 'Top Creators'?
          <>
          <span className="fontweightone text-dark mt-2 mr-3">
              <span className="globalFontSize">Sort By</span>
          </span>
          <span className="numericValue topBrandSelectBox mr-3">
                <ReactSelect items={this.state.source} handleChange={this.selectHandler} selected={this.state.selectedsource} />
          </span>
          </>
          :''
        }
        </div>
              
        <div className="chart">
          <TopNTable
            // title = {this.props.title}
            className="top-n-table"
            table={this.props.table}
            loader={this.props.loader}
            currency={this.props.currency}
          />
        </div>
      </div>
    );
  }
}

export default topNPosts;
