// Dependency Imports
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Custom Imports
import "../../static/scss/App.scss";
import Header from "../../components/header/header";
//icons
import PollIcon from "@material-ui/icons/Poll";
//helper function
import {
  Route,
  withRouter,
  // BrowserRouter as Router,
  // Redirect,
} from "react-router-dom";

//nested routes
import Dashboard from "./DashboardOverview/index";
import TotalEarning from "./DetailedAnalyticsCharts/TotalEarning";
import TopNSubscruiption from "./DetailedAnalyticsCharts/TopNSubscruiption";
import TopNPost from "./DetailedAnalyticsCharts/TopNPost";
// import TopCreators from "./DetailedAnalyticsCharts/TopCreators";
import UserSignup from "./DetailedAnalyticsCharts/UserSignup";
import TotalUsers from "./DetailedAnalyticsCharts/TotalUsers";
import UserSessionReport from "./DetailedAnalyticsCharts/UserSessionReport";
import TopNChats from "./DetailedAnalyticsCharts/TopNChats";
import { PROJECT_NAME } from "../../lib/config";

class LandingIndex extends React.Component {
  state = {
    cartLogs: " ",
    ProductRecentView: " ",
    FavProduct: " ",
    sidePanel: [

      {
        // name: "Analytics Overview",
        name : `${PROJECT_NAME}`,
        url: `${this.props.match.url}/analytics-overview`,
        icon: PollIcon,
        component: Dashboard,
      },

      {
        name: "Total Earning",
        url: `${this.props.match.url}/total-earning`,
        icon: PollIcon,
        component: TotalEarning,
      },

      {
        name: "Top Subsrciption",
        url: `${this.props.match.url}/top-subscription`,
        icon: PollIcon,
        component: TopNSubscruiption,
      },  

      {
        name: "Top Post",
        url: `${this.props.match.url}/top-n-post`,
        icon: PollIcon,
        component: TopNPost,
      },

      // {
      //   name: "Top Creators",
      //   url: `${this.props.match.url}/top-creators`,
      //   icon: PollIcon,
      //   component: TopCreators,
      // }, 
      
      {
        name: "User Signup",
        url: `${this.props.match.url}/user-signup`,
        icon: PollIcon,
        component: UserSignup,
      }, 
      
      {
        name: "Total Users",
        url: `${this.props.match.url}/total-users`,
        icon: PollIcon,
        component: TotalUsers,
      },       

      {
        name: "User Session Report",
        url: `${this.props.match.url}/user-session`,
        icon: PollIcon,
        component: UserSessionReport,
      },

      {
        name: "Top Chats",
        url: `${this.props.match.url}/top-chats`,
        icon: PollIcon,
        component: TopNChats,
      },        

    ],
  };

  render() {
    if (this.props.location.pathname === "/") {
      this.props.history.push("/dashbord/analytics-overview");
    }
    return (
      <div className="dash-board">
        <Header
          title="shoppd - Dashboard"
          data={this.state.sidePanel}
          url={`${this.props.match.url}/analytics-overview`}
        ></Header>

        <div
          className="dashbord-body"
          // style={{ height: "100vh", overflow: "auto" }}
        >
          {/* <SidePanel data={this.state.sidePanel}></SidePanel> */}

          <div className="">
            {this.state.sidePanel.map((items, index) => {
              return (
                <Route
                  key={index}
                  path={items.url}
                  name={items.name}
                  exact
                  component={items.component}
                ></Route>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

// Create validations for Props, required or type etc.
LandingIndex.propTypes = {
  dispatch: PropTypes.any.isRequired,
  lang: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => {
  return {
    index: state.index,
  };
};

export default connect(mapStateToProps, null)(withRouter(LandingIndex));
