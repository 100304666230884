import React, { Component } from "react";
import "./index.scss";

class index extends Component {
   constructor(props) {
      super(props) 
      this.state = {
      };
   };


   render() {
      return (
               <div className="d-flex w-100 justify-content-end pt-1 subGraph">
                  <div className="d-flex flex-column pr-4">
                     <div className="d-flex justify-content-center">
                        <div className="">{`${this.props.totalUser}`}</div>
                     </div>
                     <span className="" style={{fontSize: "smaller", color: "gray"}}>Total { `${this.props.userType === "All" ? 'User' : this.props.userType}`}</span>
                  </div>
                  <div className="d-flex flex-column">
                     <div className="d-flex justify-content-center">
                        <div className="">{`${this.props.totalActiveUser}`}</div>
                     </div>
                     <span className="" style={{fontSize: "smaller", color: "gray"}}>Total {`${this.props.userStatus === "All" ? "Active" : this.props.userStatus}`} { `${this.props.userType === "All" ? 'User' : this.props.userType}`}</span>
                  </div>
               </div>
      );
   }
}

export default index;
