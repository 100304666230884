import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class userLineGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      sortByDevice: 3,
      redirect: false,
    };
  }

  render() {
    return (
      <div className="overviewBody" style={{ padding: "0px 20px 0px 5px" }}>
        <ReactApexChart
          options={this.props.config.options}
          series={this.props.config.series}
          type="bar"
          height={380}
        />
      </div>
    );
  }
}

export default userLineGraph;
