import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PaginationJs from "../../../../../components/paginationJs/paginationJs";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import ImportExportIcon from '@material-ui/icons/ImportExport';

//import css
import './index.css'

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  dataHeader: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
    fontWeight: 600,
    color: "#484848",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  filter_cursor: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
    cursor:"pointer",
    fontWeight: 600,
    color: "#484848",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  data: {
    width: "150px",
    fontSize: "10px",
    fontFamily: "Poppins",
    maxWidth: "170px",
    minWidth: "150px",
    textAlign: "center",
  },
  menuItem: {
    width: "300px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  selectCountryButton: {
    background: "#FFF",
    border: "1px solid #bbb",
    borderRadius: "4px",
    padding: "7px 12px",
    marginLeft: "10px",
  },
});
class index extends Component {
  constructor() {
    super();
    this.state = {
      filterWindow: false,
    };
  }

  render() {
    console.log('sortType', this.props.sortType)
    const { classes } = this.props;
    return (
      <div className="table">
        <div className="tableWrapper">
          <Paper className={classes.root + " react-table"}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {this.props.showTable &&
                    this.props.columns.map((k, i) => (

                      <Tooltip title={k.Header} placement="top">
                        <TableCell 
                        style={this.props.filterColumnName === k.Header ? {color: 'red'} : {}}
                        className={i !==0 ? classes.filter_cursor : classes.dataHeader}
                        key={i}
                        onClick={i !==0 ? (e)=> this.props.sortType(k.Header) : ""}>
                        {k.Header}{i !==0 ? <ImportExportIcon/> : ""}
                        </TableCell>
                      </Tooltip>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.logsData.map((row, i) => {
                  return (
                    <TableRow
                      key={i}
                      style={
                        i % 2 === 0
                          ? { background: "#F6F6F6" }
                          : { background: "#FFF" }
                      }
                    >
                      <TableCell
                        className={classes.data}
                        component="th"
                        scope="row"
                      >
                        {this.props.dateRender(row.dateTime)}
                      </TableCell>
                      {Object.values(row)
                        .splice(1)
                        // .slice(0,1)
                        .map((k, index) => (
                          <TableCell
                            className={classes.data}
                            component="th"
                            scope="row"
                          >
                            {this.props.returnDataWithTwoDecsOrInteger(k, index)}
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </div>

        {/* paginationWrapper */}
        <div className="paginationWrapper">
          <PaginationJs
            changeActivePage={this.props.changeActivePage}
            activePage={this.props.page}
            totalData={this.props.dataCount}
            dataToShowHandler={this.props.dataToShowHandler}
            dataToShow={this.props.dataToShow}
          />
        </div>     
      </div>
    );
  }
}

export default withStyles(styles)(index);
