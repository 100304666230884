import { getForReport } from "../lib/request";

export const dashboardTotalEarning = (
  start_time,
  end_time,
  group_by,
  currency,
  source,
) => {
  
  let URL = `/analytics/dashboard/total-earning?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&source=${source}&currency=${currency}`
  return getForReport(URL);
}


export const dashboardTopNPosts = (
  start_time,
  end_time,
  group_by,
  currency,
  source,
) => {
  
  let URL = `/analytics/dashboard/top-n-posts?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&currency=${currency}&source=${source}`
  return getForReport(URL);
}


export const dashboardTopNSubscription = (
  start_time,
  end_time,
  group_by,
  currency,
  source,
) => {
  
  let URL = `/analytics/dashboard/top-n-subscriptions?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&currency=${currency}&source=${source}`
  return getForReport(URL);
}


export const dashboardTopCreators = (
  start_time,
  end_time,
  group_by,
  currency,
  source,
) => {
  
  let URL = `/analytics/dashboard/top-creators?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&currency=${currency}&source=${source}`
  return getForReport(URL);
}


export const dashboardTopChats = (
  start_time,
  end_time,
  group_by,
  currency,
  source,
) => {
  let URL = `/analytics/dashboard/top-n-chats?start_timestamp=${start_time}&end_timestamp=${end_time}&group_by=${group_by}&currency=${currency}&source=${source}`
  return getForReport(URL);
}
